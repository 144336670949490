/* .Travaux {
  overflow-x: hidden;
} */

.Main {
  padding: 0 1em;
}
.CardListTitle {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.CardListTitle span {
  font-size: 1.5rem;
  font-weight: bold;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Travaux {
    margin: 0 2em;
    margin-top: 1em;
  }
  .MainCards {
    display: flex;
    position: relative;
    min-height: 40em;
  }
  .NoCard {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Travaux {
    margin: 0 10em;
  }
}
