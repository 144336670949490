.Toolbar {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 3em;
}

.Logo h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  font-family: "Arial Black", Gadget, sans-serif;
  cursor: pointer;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Toolbar {
    padding: 0 1em;
  }
  .Logo h3 {
    font-size: 1.8rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar {
    padding: 2em 1em;
  }

  .Logo h3 {
    font-size: 2rem;
  }
}
