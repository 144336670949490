.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e0004d;
}
.LogoContainer {
  width: 15em;
  margin: 1em 0;
}
.LogoContainer img {
  width: 100%;
}
.LegalLinks {
  display: flex;
  margin-top: 1em;
}
.LegalLinks p {
  cursor: pointer;
  margin: 0 1em;
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
}
.Copyrights {
  margin: 1em 0;
  color: white;
  text-align: center;
  font-size: 0.8rem;
}
.Copyrights span {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: "Arial Black", Gadget, sans-serif;
}
