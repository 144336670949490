.IntroModal {
  height: 50vh;
  overflow-y: scroll;
  margin-bottom: 3em;
}

.IntroTitle {
  /* margin-right: 1em; */
}

.IntroDescription {
  margin-right: 1em;
}

.IntroButton {
  margin-top: 1.5em;
  text-align: center;
  margin-right: 1em;
  margin-bottom: 1em;
  position: fixed;
  bottom: 0.2em;
  width: 90%;
}

.Personnalisable {
  cursor: pointer;
  /* font-weight: 700; */
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #bbd5ff 40%);
}

.Informative {
  cursor: pointer;
  /* font-weight: 700; */
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #fcd79d 40%);
}
