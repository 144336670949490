* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Serif", sans-serif;
  background: white;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(./assets/Futura_Extra_Bold.otf) format("truetype");
}
