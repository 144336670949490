.Card {
  /* background-color: #e0ecff; */
  display: flex;
  border-radius: 14px;
  position: relative;
  padding: 0.3em 0;
  max-width: 22em;
  margin-bottom: 1em;
  cursor: pointer;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
}
.SelectedCard {
  border: 2px solid #e57373;
}

.Thumbnail {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.Thumbnail img {
  border-radius: 14px;
  width: 100%;
}

.Main {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Titre {
  display: flex;
  flex-direction: column;
}

.Description {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Icon {
  position: absolute;
  bottom: 0.3em;
  right: 1em;
}

.Tags {
  font-size: 0.55rem;
  color: white;
}
.Tags span {
  background-color: #757575;
  padding: 0.2em 0.4em;
  border-radius: 24px;
  text-transform: capitalize;
}
.Tags :last-child {
  margin-left: 0.4em;
}
