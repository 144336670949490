@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Serif", sans-serif;
  background: white;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(/static/media/Futura_Extra_Bold.db7c22c7.otf) format("truetype");
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Login_Wrapper__TAMaX {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.Login_LoginContainer__PNnqk {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.Login_Login__13AJy {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.Login_ImgWrapper__20USz {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.Login_ImgWrapper__20USz img {
  width: 100%;
}

.Login_LoginTitle__2lHFS {
  font-size: 2em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 40px;
}
.Login_LoginTitle__2lHFS span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ====FORM STYLING START====== */
.Login_InputGroup__Yuqmo {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Login_Input__2NDh4 {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Login_Input__2NDh4:focus {
  outline: none;
}
.Login_Phone__2WhHx {
  padding-left: 45px;
}
.Login_InputGroup__Yuqmo i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
.Login_MUIInputGroup__1qxL8 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.Login_InputError__dZ-Sc {
  font-size: 0.85rem;
  color: #e0004d;
  margin-top: 0.5em;
}
/* LABEL ======= */
.Login_InputLabel__3B5PP {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Login_Input__2NDh4:focus ~ .Login_InputLabel__3B5PP,
.Login_Input__2NDh4:valid ~ .Login_InputLabel__3B5PP {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Login_Bar__1KIog {
  position: relative;
  display: block;
  width: 100%;
}
.Login_Bar__1KIog:before,
.Login_Bar__1KIog:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Login_Bar__1KIog:before {
  left: 50%;
}
.Login_Bar__1KIog:after {
  right: 50%;
}
/* active state */
.Login_Input__2NDh4:focus ~ .Login_Bar__1KIog:before,
.Login_Input__2NDh4:focus ~ .Login_Bar__1KIog:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Login_Highlight__3zL2i {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Login_Input__2NDh4:focus ~ .Login_Highlight__3zL2i {
  animation: Login_inputHighlighter__1h5KU 0.3s ease;
  -webkit-animation: Login_inputHighlighter__1h5KU 0.3s ease;
  -moz-animation: Login_inputHighlighter__1h5KU 0.3s ease;
}
/* Animations */
@keyframes Login_inputHighlighter__1h5KU {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Login_inputHighlighter__1h5KU {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Login_Indicatif__nexoy {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Login_Input__2NDh4:focus ~ .Login_Indicatif__nexoy,
.Login_Input__2NDh4:valid ~ .Login_Indicatif__nexoy {
  opacity: 1;
}

/* ========= CGU CHECKBOX =========== */

.Login_CGUGroup__3eGSZ input[type="checkbox"] {
  display: none;
}
.Login_CGUGroup__3eGSZ input[type="checkbox"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.Login_CGUGroup__3eGSZ input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
.Login_CGUGroup__3eGSZ input[type="checkbox"]:checked + label:before {
  letter-spacing: 8px;
}

.Login_CGUGroup__3eGSZ input[type="checkbox"] + label:before {
  content: "\f096";
}
.Login_CGUGroup__3eGSZ input[type="checkbox"] + label:before {
  letter-spacing: 10px;
}

/* CGU Link ====== */
.Login_CGUGroup__3eGSZ label {
  color: #828489;
  font-size: 0.9em;
}
.Login_CGUGroup__3eGSZ span {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ======SUBMIT BUTTON ====== */
.Login_SubmitButtonContainer__1NQYO {
  margin-top: 40px;
}

/* Anon link */
.Login_AnonLogin__2PTsU {
  margin-top: 1em;
  text-align: center;
}

.RefInfo_RefInfoTitle__2IyaE {
  display: flex;
}
.RefInfo_RefInfoTitle__2IyaE h3 {
  margin-left: 8px;
}

.RefInfo_RefWrapper__2f2SQ img {
  width: 100%;
  margin-top: 15px;
}

.CGU_CGUTitle__2CJ9l {
  text-align: center;
}

.CGU_CGUWrapper__1xM4d {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.CGU_Article__38J68 {
  margin-bottom: 1em;
}

.CGU_ArticleTitle__2HHS1 {
  margin-bottom: 0.5em;
}
.CGU_ArticleSubtitle__1dTwA {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__WnqZd {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__WnqZd p {
  margin-bottom: 0.3em;
}

.Modal_Modal__14sSw {
  position: fixed;
  z-index: 500;
  background: white;
  border-radius: 9px;
  width: 90%;
  padding: 16px;
  left: 5%;
  top: 30%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal_Modal__14sSw {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop_Backdrop__2BpTQ {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  /* background-color: rgba(229, 229, 229, 0.8); */
  background-color: rgba(0, 0, 0, 0.5);
}

.SubmitButton_SubmitButton__3ACP3 {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1em 1em;
  min-width: 10em;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  text-align: center;
  /* Primaire Rouge 1 */
  background: #e0004d;
  /* 6dp elevation */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.SubmitButton_SubmitButton__3ACP3:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.SubmitButton_SubmitButton__3ACP3:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

/* Invalid state */
.SubmitButton_Invalid__1b2rV i {
  -webkit-transform: translate(-30vw, 11vh);
          transform: translate(-30vw, 11vh);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
}
.SubmitButton_Invalid__1b2rV span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* transition: all linear 0.2s; */
}

/* Valid state */
.SubmitButton_Valid__1dBcL i {
  color: #ffc300;
  font-size: 1.4em;
  margin-left: 0.4em;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 1;
}

/* Loading state */
.SubmitButton_Clicked__Y2Y_g i {
  -webkit-transform: translate(100vw, -90vh);
          transform: translate(100vw, -90vh);
  -webkit-transition: all linear 1.3s;
  transition: all linear 1.3s;
}

.CircularLoading_CircularLoading__3Fsty {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Home_SectionTitle__QOtmB {
  text-align: center;
  font-family: "Merriweather", serif;
}
.Home_SectionTitle__QOtmB .Home_Emoji__1hgCk {
  font-size: 2.8em;
}
.Home_SectionTitle__QOtmB h3 {
  font-size: 1.5em;
}
.Home_SectionSubtitle__3QQql {
  font-size: 1rem;
  margin: 0.8em 2em;
  margin-left: 4em;
  color: #898786;
}

.Home_EmojiText__2l5T5 {
  display: flex;
  margin: 0 2em;
  color: #5b5855;
}
.Home_EmojiText__2l5T5 .Home_Emoji__1hgCk {
  font-size: 1.2em;
}

.Home_EmojiText__2l5T5 p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.Home_YoutubePlayer__2apDt {
  margin: 1.5em auto;
  display: flex;
  justify-content: center;
}

.Home_ParticiperButton__1HnYk {
  text-align: center;
  margin: 1.5em 0;
}

.Home_HighlightNote__S9YGv {
  margin: 2em 4em;
}
.Home_HighlightNote__S9YGv span {
  display: inline-block;
  padding: 0.25em 2em;
  background-color: #e0004d;
  margin-bottom: 1em;
  border-radius: 3px;
}
.Home_HighlightNote__S9YGv p {
  text-align: center;
  color: #e0004d;
  font-weight: bold;
  font-size: 1.1rem;
}

.Home_Illustration__2hN09 {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
@media screen and (max-width: 767px) {
  .Home_CarouselSection__104gT {
    margin: 30px 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .Home_CarouselSection__104gT::-webkit-scrollbar {
    display: none; /* Safari & Chrome*/
  }

  .Home_CarouselItem__3ajI6 {
    flex: 0 0 auto;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 30px;
    width: 275px;
    height: 275px;
    box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .Home_CarouselItem__3ajI6:first-child {
    margin-left: 40px;
  }
  .Home_CourselItem__vg_3t:last-child {
    margin-right: 20px;
  }
}

.Home_GrandesEtapes__1DrzK {
  display: flex;
  justify-content: center;
}
.Home_GrandesEtapes__1DrzK img {
  width: 80%;
  max-width: 33em;
  margin-bottom: 2em;
}
.Home_HeroImage__TXyRy {
  width: 100%;
  margin-top: 3.5em;
  margin-bottom: 1.5em;
  z-index: -1;
}
.Home_HeroImage__TXyRy img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
  .Home_HeroImage__TXyRy {
    margin-top: 0em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Home_HomeCardBackground__2m_Cn {
    padding: 0 7em;
    background-color: #f3f2f0;
  }
  .Home_HomeCardContent__vA6eC {
    background-color: white;
    padding-bottom: 2em;
  }
  .Home_HeroImage__TXyRy {
    width: 100%;
    margin-top: 3.5em;
    margin-bottom: 1.5em;
    z-index: -1;
  }
  .Home_HeroImage__TXyRy img {
    width: 100%;
  }
  /* .HeroImage img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  } */
  .Home_Section__Bjl4X {
    margin: 0 2em;
  }
  .Home_SectionTitle__QOtmB {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-align: left;
  }
  .Home_SectionTitle__QOtmB .Home_Emoji__1hgCk {
    font-size: 3rem;
  }
  .Home_SectionSubtitle__3QQql {
    font-size: 1.2rem;
    margin: 0.8em 0;
    margin-left: 3.5em;
  }
  .Home_EmojiText__2l5T5 p {
    font-size: 1rem;
  }

  .Home_HighlightNote__S9YGv p {
    font-size: 1.3rem;
  }
  .Home_ContactForm__4ZE1i {
    margin: 0 auto;
    max-width: 40em;
  }

  /* Caroussel */
  .Home_CarouselSection__104gT {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2em 0;
  }

  .Home_CarouselItem__3ajI6 {
    margin: 0.5em 1em;
    width: 15em;
    height: 15em;
    box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Home_HomeCardBackground__2m_Cn {
    padding: 0 13em;
  }

  .Home_HeroImage__TXyRy {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 4em;
    margin-top: 3em;
  }
  .Home_Section__Bjl4X {
    margin: 0 5em;
  }
  .Home_Illustration__2hN09 {
    width: 25em;
    height: 25em;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
  .Home_HomeCardBackground__2m_Cn {
    padding: 0 16em;
  }
}

.Blog_Blog__3kCEn {
  margin: 50px 0;
  box-sizing: border-box;
}

.Blog_BlogTitle__8q6ed {
  display: flex;
  margin: 0 20px;
}
.Blog_BlogTitle__8q6ed h2 {
  margin-left: 5px;
}

/* Small tablets */
@media (min-width: 620px) {
  .Blog_BlogTitle__8q6ed {
    margin: 0 50px;
  }
}

.BlogCard_BlogCard__3-0uz {
  margin: 30px auto;
  max-width: 23rem;
  min-height: 32rem;
  width: 75%;
  background: #f3f2f0;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 8px;
  overflow: hidden;
}

.BlogCard_BlogCardImage__2MHcj {
  height: 22em;
  width: 100%;
  z-index: -1;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  background-repeat: no-repeat;
  background-size: cover;
}

.BlogCard_BlogCardDate__1XyOK {
  text-align: end;
  margin: 0.7rem 1.5rem;
  font-size: 0.9em;
  color: #9dabb6;
}

.BlogCard_BlogCardContent__-v4gd {
  margin: 0.7rem 1.5rem;
}
.BlogCard_BlogCardContent__-v4gd p {
  margin: 0.3rem 0;
}

.BlogCard_BlogCardButton__1iHmk {
  text-align: end;
  margin: 0.7rem 1.5rem;
  margin-top: 2rem;
}

/* Small tablets */
@media (min-width: 620px) {
  .BlogCard_BlogCard__3-0uz {
    margin: 50px auto;
  }
}

.LongButton_LongButton__1DHw3 {
  font-family: inherit;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 15px;
  outline: none;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 4px;
  cursor: pointer;
}
.LongButton_LongButton__1DHw3:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.ContactForm_ContactForm__1A8pa {
  margin: 1.5em 0.3em;
}
.ContactForm_ContactFormTitle__213lX {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.ContactForm_Emoji__3ybgN {
  font-size: 1.5rem;
}
.ContactForm_ContactFormFields__1-CSs {
  margin: 2em;
  display: flex;
  flex-direction: column;
}
.ContactForm_Button__1Caxq {
  text-align: end;
}
.ContactForm_Success__32Xvy {
  margin-left: 0.2em;
  margin-bottom: 1em;
  color: green;
  font-size: 0.8rem;
}
.ContactForm_Error__2mAND {
  margin-bottom: 1em;
  margin-left: 0.2em;
  color: red;
  font-size: 0.8rem;
}

.Footer_Footer__3WqDu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e0004d;
}
.Footer_LogoContainer__IHnr5 {
  width: 15em;
  margin: 1em 0;
}
.Footer_LogoContainer__IHnr5 img {
  width: 100%;
}
.Footer_LegalLinks__JNzwi {
  display: flex;
  margin-top: 1em;
}
.Footer_LegalLinks__JNzwi p {
  cursor: pointer;
  margin: 0 1em;
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
}
.Footer_Copyrights__1_mE6 {
  margin: 1em 0;
  color: white;
  text-align: center;
  font-size: 0.8rem;
}
.Footer_Copyrights__1_mE6 span {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: "Arial Black", Gadget, sans-serif;
}

.PDC_CGUTitle__2ip40 {
  text-align: center;
}

.PDC_CGUWrapper__2tWY_ {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.PDC_Article__1Fq39 {
  margin-bottom: 1em;
}

.PDC_ArticleTitle__22JO- {
  margin-bottom: 0.5em;
}
.PDC_ArticleSubtitle__ocQUC {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2CeUx {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2CeUx p {
  margin-bottom: 0.3em;
}

.Separator_Separator__33hOw {
  margin: 0 auto;
  height: 4px;
  width: 90%;
  max-width: 60em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset 1px 3px 5px #a6abbd, inset 7px 4px 14px #fafbff;
  border-radius: 4px;
}

/* .Content {
  margin-top: 56px;
} */

/* Small tablets */

/* Large tablets & laptops */
@media (min-width: 960px) {
  .Layout_Content__2-Jse {
    margin-top: 0px;
  }
}

.Toolbar_Toolbar__1MOje {
  height: 57px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ebecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
}

/* .Logo {
  height: 60%;
} */
.Toolbar_Logo__1mqWr h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 619px) {
  .Toolbar_DesktopOnly__3aOKR {
    display: none;
  }
}

/* Small tablets */
@media (min-width: 620px) {
  .Toolbar_Toolbar__1MOje {
    /* box-shadow: none; */
    height: 70px;
    padding: 0 40px;
    /* position: relative; */
    background-color: white;
  }
  .Toolbar_Logo__1mqWr h3 {
    font-size: 1.8rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 960px) {
  .Toolbar_Toolbar__1MOje {
    /* height: 100px; */
    padding: 10px 80px;
  }
  .Toolbar_Logo__1mqWr h3 {
    font-size: 2rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__1MOje {
    padding: 10px 100px;
  }
}

.SideDrawer_SideDrawer__1J2Fu {
  position: fixed;
  width: 280px;
  max-width: 60%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #ebecf0;
  padding: 32px 16px;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 620px) {
  .SideDrawer_SideDrawer__1J2Fu {
    display: none;
  }
}

.SideDrawer_Open__244uL {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_Close__1zQcZ {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.SideDrawer_Logo__1cGUZ {
  height: 11%;
  margin-bottom: 32px;
}

.NavigationItems_NavigationItems__3NDB_ {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

/* Small tablets */
@media screen and (min-width: 620px) {
  .NavigationItems_NavigationItems__3NDB_ {
    flex-flow: row;
    width: 50vw;
  }

  .NavigationItems_Participer__Ip4cn {
    margin-left: 25px;
  }
}

/* Large tablets & laptops */
@media (min-width: 960px) {
  .NavigationItems_NavigationItems__3NDB_ {
    width: 45vw;
  }
}

.NavigationItem_NavigationItem__18_m4 {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem_NavigationItem__18_m4 a {
  color: #2a2f39;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 7px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.NavigationItem_NavigationItem__18_m4 a:hover,
.NavigationItem_NavigationItem__18_m4 a:active,
.NavigationItem_NavigationItem__18_m4 a.NavigationItem_active__3H_vQ {
  color: #e6404d;
  font-weight: bold;
  box-shadow: inset 5px 5px 10px #a6abbd, inset -5px -5px 10px #fafbff;
}

/* Small tablets */
@media (min-width: 620px) {
  .NavigationItem_NavigationItem__18_m4 {
    margin: 10px 10px;
    padding: 10 30px;
  }
  .NavigationItem_NavigationItem__18_m4 a {
    border: none;
    box-shadow: none;
    padding: 10px 0;
  }
  .NavigationItem_NavigationItem__18_m4 a:hover,
  .NavigationItem_NavigationItem__18_m4 a:active,
  .NavigationItem_NavigationItem__18_m4 a.NavigationItem_active__3H_vQ {
    box-shadow: none;
    font-weight: bold;
    color: #e6404d;
  }
}

.ProfileSetup_Wrapper__1n3l4 {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.ProfileSetup_ProfileSetupContainer__3lUth {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.ProfileSetup_ProfileSetup__2iH3N {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ProfileSetup_ImgWrapper__3UfsU {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.ProfileSetup_ImgWrapper__3UfsU img {
  width: 100%;
}

.ProfileSetup_Title__3fVNM {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.ProfileSetup_Title__3fVNM span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}
.ProfileSetup_CircularProgress__2p9yW {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ProfileSetup_QuestionContainer__bkhLY {
  height: 15rem;
  margin-bottom: 3.5rem;
}

/* START =========== Buttons */
.ProfileSetup_ButtonsContainer__1k_Pk {
  position: absolute;
  left: 0;
  bottom: 2rem;
  right: 0;
  text-align: center;
}
.ProfileSetup_Button__2YAgn {
  display: inline-block;
  margin: 0 1rem;
}
.ProfileSetup_SubmitButton__2CN9z {
  line-height: 0;
  width: auto;
  padding: 0 12px;
  height: 48px;
  margin: 0 8px;
  font-size: 1.2rem;

  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;

  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.NextButton_NextButton__1DhNG {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: rgba(0, 78, 124, 0.178);
  border: none;
  border-radius: 50px;
}

.NextButton_NextButton__1DhNG:hover {
  background-color: #003c71;
  color: white;
}

.NextButton_NextButton__1DhNG:active {
  background-color: #003c71;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.NextButton_NextButton__1DhNG i {
  padding-left: 2px;
}

.BackButton_BackButton__2i0hK {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: rgba(0, 78, 124, 0.178);
  border: none;
  border-radius: 50px;
}

.BackButton_BackButton__2i0hK:hover {
  background-color: #003c71;
  color: white;
}

.BackButton_BackButton__2i0hK:active {
  background-color: #003c71;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.BackButton_BackButton__2i0hK i {
  padding-right: 2px;
}

.CircularProgressBar_Circle__KjRFV {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid #e0004d;
  box-sizing: border-box;
  border-radius: 50%;
  /* animation-name: progress;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Circle__KjRFV::after {
  content: "";
  position: absolute;
  top: -21px;
  left: -21px;
  width: 180px;
  height: 180px;
  border: 22px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
          clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
  /* animation-name: progress_after;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Etape__8t7T- {
  margin-top: 0.35em;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

/* SART ------------Basic Input--------------- */
.Input_InputGroup__2wtS- {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Input_Input__2G4ri {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Input_Input__2G4ri:focus {
  outline: none;
}
.Input_Phone__pc-2l {
  padding-left: 35px;
}
.Input_InputGroup__2wtS- i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
/* LABEL ======= */
.Input_InputLabel__oReWm {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Input_Input__2G4ri:focus ~ .Input_InputLabel__oReWm,
.Input_Input__2G4ri:valid ~ .Input_InputLabel__oReWm {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Input_Bar__1XiMp {
  position: relative;
  display: block;
  width: 100%;
}
.Input_Bar__1XiMp:before,
.Input_Bar__1XiMp:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Input_Bar__1XiMp:before {
  left: 50%;
}
.Input_Bar__1XiMp:after {
  right: 50%;
}
/* active state */
.Input_Input__2G4ri:focus ~ .Input_Bar__1XiMp:before,
.Input_Input__2G4ri:focus ~ .Input_Bar__1XiMp:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Input_Highlight__1g4as {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Input_Input__2G4ri:focus ~ .Input_Highlight__1g4as {
  animation: Input_inputHighlighter__1DkrQ 0.3s ease;
  -webkit-animation: Input_inputHighlighter__1DkrQ 0.3s ease;
  -moz-animation: Input_inputHighlighter__1DkrQ 0.3s ease;
}
/* Animations */
@keyframes Input_inputHighlighter__1DkrQ {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Input_inputHighlighter__1DkrQ {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Input_Indicatif__2mKO5 {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Input_Input__2G4ri:focus ~ .Input_Indicatif__2mKO5,
.Input_Input__2G4ri:valid ~ .Input_Indicatif__2mKO5 {
  opacity: 1;
}

/* START -----------Radio Button----------- */

.Input_RadioFormElement__L0OiA {
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Input_RadioFormElement__L0OiA::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Input_RadioDivElement__SC16w {
  flex: 0 0 auto;
  margin: 20px 0;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  position: relative;
}
.Input_RadioDivElement__SC16w:first-child {
  margin-left: 10vw;
}

.Input_RadioDivElement__SC16w input {
  opacity: 0;
}

.Input_RadioDivElement__SC16w label {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  /* OmbreOUT */
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;
  cursor: pointer;
}
.Input_RadioDivElement__SC16w span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Input_RadioDivElement__SC16w input[type="radio"]:checked + label {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
  color: #e6404d;
  font-weight: bold;
}

/* END -----------Radio Button----------- */

/* Small tablets */

.QuestionTwo_QuestionTwo__2UouL {
  margin: 1.3em 0;
  text-align: center;
}
.QuestionTwo_QuestionTwo__2UouL p {
  margin: 20px;
  font-size: 1.1rem;
}

.Radio_FormTitle__2Mu5o {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.Radio_Form__jX1aF {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Radio_FormElement__ABrDV {
  margin: 5px 5px;
  width: 3.2em;
  height: 3.2em;
  position: relative;
}

.Radio_FormElement__ABrDV input[type="radio"] {
  display: none;
}

.Radio_FormElement__ABrDV label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 15%;
  border: none;
  background-color: rgba(0, 78, 124, 0.178);
  color: #777;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Radio_FormElement__ABrDV input[type="radio"]:checked + label {
  background-color: #003c71;
  color: white;
}

/* .Travaux {
  overflow-x: hidden;
} */

.Travaux_Main__3R15G {
  padding: 0 1em;
}
.Travaux_CardListTitle__3ThAS {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Travaux_CardListTitle__3ThAS span {
  font-size: 1.5rem;
  font-weight: bold;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Travaux_Travaux__2tFkB {
    margin: 0 2em;
    margin-top: 1em;
  }
  .Travaux_MainCards__CA2NB {
    display: flex;
    position: relative;
    min-height: 40em;
  }
  .Travaux_NoCard__1wbre {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Travaux_Travaux__2tFkB {
    margin: 0 10em;
  }
}

.IntroModal_IntroModal__1KxEO {
  height: 50vh;
  overflow-y: scroll;
  margin-bottom: 3em;
}

.IntroModal_IntroTitle__3WIuE {
  /* margin-right: 1em; */
}

.IntroModal_IntroDescription__3Uy_V {
  margin-right: 1em;
}

.IntroModal_IntroButton__2aw5w {
  margin-top: 1.5em;
  text-align: center;
  margin-right: 1em;
  margin-bottom: 1em;
  position: fixed;
  bottom: 0.2em;
  width: 90%;
}

.IntroModal_Personnalisable__31ohR {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #bbd5ff));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #bbd5ff 40%);
}

.IntroModal_Informative__112xW {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #fcd79d));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #fcd79d 40%);
}

.Toolbar_Toolbar__2a1Kh {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 3em;
}

.Toolbar_Logo__2Qq1- h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  font-family: "Arial Black", Gadget, sans-serif;
  cursor: pointer;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Toolbar_Toolbar__2a1Kh {
    padding: 0 1em;
  }
  .Toolbar_Logo__2Qq1- h3 {
    font-size: 1.8rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Toolbar__2a1Kh {
    padding: 2em 1em;
  }

  .Toolbar_Logo__2Qq1- h3 {
    font-size: 2rem;
  }
}

.Filter_Filter__377FT {
  margin-bottom: 0.5em;
}
.Filter_Filter__377FT p {
  font-size: 0.9rem;
}
.Filter_Filter__377FT span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Filter_Categories__19WQq form {
  display: flex;
  margin: 0.4rem 0;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Filter_Categories__19WQq form::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Filter_Personnalisable__PiggO {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Personnalisable__PiggO label {
  background: #f1f3f5;
  color: #828489;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Personnalisable__PiggO input[type="checkbox"] {
  display: none;
}

.Filter_Personnalisable__PiggO input[type="checkbox"]:checked + label {
  background: #e0ecff;
  color: #263238;
}

/* =========================== */
.Filter_Informative__3arWB {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Informative__3arWB label {
  background: #f1f3f5;
  color: #828489;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Informative__3arWB input[type="checkbox"] {
  display: none;
}

.Filter_Informative__3arWB input[type="checkbox"]:checked + label {
  background: #fff3e0;
  color: #263238;
}

.CardList_CardList__3-PHf {
  margin-bottom: 2em;
}

.CardList_List__2f3fU {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardList_List__2f3fU {
    align-items: flex-start;
    width: 21em;
  }
}

.Card_Card__397Rt {
  /* background-color: #e0ecff; */
  display: flex;
  border-radius: 14px;
  position: relative;
  padding: 0.3em 0;
  max-width: 22em;
  margin-bottom: 1em;
  cursor: pointer;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
}
.Card_SelectedCard__396jC {
  border: 2px solid #e57373;
}

.Card_Thumbnail__j6F96 {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.Card_Thumbnail__j6F96 img {
  border-radius: 14px;
  width: 100%;
}

.Card_Main__LxB1W {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card_Titre__2fueC {
  display: flex;
  flex-direction: column;
}

.Card_Description__3fJvX {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Card_Icon__7rTO5 {
  position: absolute;
  bottom: 0.3em;
  right: 1em;
}

.Card_Tags__5KQBk {
  font-size: 0.55rem;
  color: white;
}
.Card_Tags__5KQBk span {
  background-color: #757575;
  padding: 0.2em 0.4em;
  border-radius: 24px;
  text-transform: capitalize;
}
.Card_Tags__5KQBk :last-child {
  margin-left: 0.4em;
}

.CardModal_CardModal__d6Fqi {
  position: fixed;
  z-index: 500;
  background: white;
  overflow-y: scroll;
  /* border-radius: 9px; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardModal_CardModal__d6Fqi {
    position: static;
    overflow-y: hidden;
    background-color: #f3f2f0;
    width: auto;
    height: auto;
    padding: 2em;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1400px) {
  .CardModal_CardModal__d6Fqi {
    padding: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
  }
}

.concertableLayoutOne_CardHeader__1giwV {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.concertableLayoutOne_CardTitle__1cMkg h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.concertableLayoutOne_CardTitle__1cMkg h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.concertableLayoutOne_Score__39vYA {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.concertableLayoutOne_Score__39vYA p {
  font-size: 0.9rem;
  color: #e0004d;
}
.concertableLayoutOne_Score__39vYA span {
  font-weight: bold;
}

.concertableLayoutOne_ImagePreview__Z7IJx {
  margin: 1.5em auto;
  width: 80%;
  max-width: 25em;
}
.concertableLayoutOne_ImagePreview__Z7IJx img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.concertableLayoutOne_CardContent__KDP0e {
  margin: 0 1.5em;
}

.concertableLayoutOne_Emoji__3_tnz {
  font-size: 1.8rem;
}
.concertableLayoutOne_DescriptionTitle__NzyOX p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.concertableLayoutOne_DescriptionContent__wMneP {
  margin-top: 0.5em;
  color: #898786;
  font-size: 0.8rem;
}

.concertableLayoutOne_QuestionsHeader__3n8az {
  color: #e0004d;
  margin-top: 0.7em;
}

.concertableLayoutOne_QuestionTitle__fY5qY {
  margin-top: 0.5em;
  font-size: 0.85em;
  display: flex;
}
.concertableLayoutOne_QuestionTitle__fY5qY p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.concertableLayoutOne_RadioForm__vu28U {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.concertableLayoutOne_RadioElement__2KkQI {
  margin: 1em 0.8em;
}

.concertableLayoutOne_RadioElement__2KkQI label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.concertableLayoutOne_RadioElement__2KkQI p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.concertableLayoutOne_RadioElement__2KkQI img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5em;
  /* border-radius: 50%; */
  /* box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14); */
}

.concertableLayoutOne_RadioElement__2KkQI input {
  display: none;
}

.concertableLayoutOne_RadioElement__2KkQI input[type="radio"]:checked + label img {
  border: 3px solid #e0004d;
}
.concertableLayoutOne_RadioElement__2KkQI input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* TESTING ====================================================================== */
/* .RadioForm .RadioElementTwo {
  margin: 10px 5px 5px 0;
  display: inline-block;
} */

.concertableLayoutOne_RadioFormTwo__8yc4J {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: left; */
}

.concertableLayoutOne_RadioElementTwo__1lIZH {
  margin: 1em 1em;
}

.concertableLayoutOne_RadioElementTwo__1lIZH input[type="radio"] {
  display: none;
}

.concertableLayoutOne_RadioElementTwo__1lIZH input[type="radio"] + label + p {
  text-align: center;
  width: 8em;
}

.concertableLayoutOne_RadioElementTwo__1lIZH input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 8em;
  width: 8em;
  display: inline-block;
  position: relative;
  border: 4px solid rgb(196, 196, 196);
  border-radius: 5%;
}

.concertableLayoutOne_RadioElementTwo__1lIZH input[type="radio"] + label:hover {
  cursor: pointer;
}

.concertableLayoutOne_RadioElementTwo__1lIZH input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.concertableLayoutOne_RadioElementTwo__1lIZH input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.concertableLayoutOne_RadioElementTwo__1lIZH input[type="radio"]:checked + label i {
  display: inline-block;
}
.concertableLayoutOne_RadioElementTwo__1lIZH input[type="radio"]:checked + label + p {
  font-weight: bold;
}

/* ============ END RADIO FORM */
.concertableLayoutOne_Button__1RuzY {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.concertableLayoutOne_CommentSection__2Wa1h {
  margin-top: 1em;
  text-align: center;
}

.concertableLayoutOne_MUIForm__3D-nq {
  margin-left: 2em;
}

.concertableLayoutOne_NCSection__rrJ9e {
  margin-top: 1.1em;
}
.concertableLayoutOne_MapImage__6Fe33 {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.concertableLayoutOne_Notices__1033p {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .concertableLayoutOne_Card__3T-f_ {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .concertableLayoutOne_LeftSection__20c1y {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .concertableLayoutOne_RightSection__1Ny0h {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .concertableLayoutOne_RightFooter__3WeDH {
    margin-top: 2em;
  }
}

.NonConcertableLayoutOne_CardHeader__2JMV- {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.NonConcertableLayoutOne_CardTitle__3pI7g h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.NonConcertableLayoutOne_CardTitle__3pI7g h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.NonConcertableLayoutOne_Score__3MkCA {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.NonConcertableLayoutOne_Score__3MkCA p {
  font-size: 0.9rem;
  color: #e0004d;
}
.NonConcertableLayoutOne_Score__3MkCA span {
  font-weight: bold;
}

.NonConcertableLayoutOne_YoutubePlayer__dMYYa {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.NonConcertableLayoutOne_CardContent__Ygo9Q {
  margin: 0 1.5em;
}

.NonConcertableLayoutOne_Emoji__3mClx {
  font-size: 1.8rem;
}
.NonConcertableLayoutOne_DescriptionTitle__gwYrl p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.NonConcertableLayoutOne_DescriptionContent__357bW {
  margin-top: 0.5em;
  color: #898786;
  font-size: 0.8rem;
}

.NonConcertableLayoutOne_NCSection__nA5v0 {
  margin-top: 1.1em;
}

.NonConcertableLayoutOne_NCItem__23gn5 {
  margin-left: 1em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}
.NonConcertableLayoutOne_NCItem__23gn5 .NonConcertableLayoutOne_Emoji__3mClx {
  font-size: 1.7em;
}
.NonConcertableLayoutOne_NCItem__23gn5 p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.NonConcertableLayoutOne_MapImage__pPnoN {
  margin-top: 0.85em;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

/* ============ END RADIO FORM */
.NonConcertableLayoutOne_Button__104rU {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.NonConcertableLayoutOne_CommentSection__1uk6p {
  margin-top: 1em;
  text-align: center;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .NonConcertableLayoutOne_Card__1xkPC {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .NonConcertableLayoutOne_LeftSection__jGhRl {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .NonConcertableLayoutOne_RightSection__2pKJL {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .NonConcertableLayoutOne_MUIForm__jSMnX {
    margin-bottom: 8em;
  }
  .NonConcertableLayoutOne_RightFooter__EhWIV {
    position: absolute;
    bottom: 0;
    right: 1em;
    width: 90%;
  }
}

.ProfilePage_HeaderImageWrapper__tHAOv {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.ProfilePage_HeaderImageWrapper__tHAOv img {
  width: 80%;
}

.ProfilePage_ProfilePage__9l3zZ h1 {
  margin: 1rem 2rem;
}
.ProfilePage_ProfilePage__9l3zZ h3 {
  margin: 1rem 0 1rem 2rem;
}
.ProfilePage_SubmitButton__2r5Bn {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.OvalButton_OvalButton__2vM19 {
  display: flex;
  margin: 0 auto;
  padding: 20px 40px;
  border: 0;
  outline: 0;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 41px;
}

.OvalButton_OvalButton__2vM19:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.Resultats_Main__1-eZx {
  padding: 0 1em;
}
.Resultats_CardListTitle__1tw59 {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Resultats_CardListTitle__1tw59 span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Resultats_IllustrationsSection__1mFhU {
  padding: 0 1em;
  margin-top: 0.5em;
}
.Resultats_StatsIllustration__3lFj6 {
  margin-top: 2em;
}
.Resultats_StatsContent__2tbIk {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Resultats_StatsContent__2tbIk h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.Resultats_StatsContent__2tbIk p {
  font-size: 2rem;
  font-weight: bold;
}
.Resultats_Illustration__2CkiS {
  margin: 1em auto;
  width: 80%;
  max-width: 25em;
  display: flex;
  justify-content: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Resultats_Travaux__1E-gI {
    margin: 0 2em;
    margin-top: 1em;
  }
  .Resultats_MainCards__3VM4b {
    display: flex;
    position: relative;
    min-height: 40em;
  }
  .Resultats_NoCard__1Tpv2 {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Resultats_Travaux__1E-gI {
    margin: 0 10em;
  }
  /* .IllustrationsContent {
    display: flex;
  } */
}


.ResultsConcertableLayout_CardHeader__3lcdK {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.ResultsConcertableLayout_CardTitle__1LKuk h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.ResultsConcertableLayout_CardTitle__1LKuk h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.ResultsConcertableLayout_Score__1fJ3R {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.ResultsConcertableLayout_Score__1fJ3R p {
  font-size: 0.9rem;
  color: #e0004d;
}
.ResultsConcertableLayout_Score__1fJ3R span {
  font-weight: bold;
}

.ResultsConcertableLayout_ImagePreview__3DD3S {
  margin: 1.5em auto;
  width: 80%;
  max-width: 25em;
}
.ResultsConcertableLayout_ImagePreview__3DD3S img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.ResultsConcertableLayout_CardContent__3MUsX {
  margin: 0 1.5em;
}

.ResultsConcertableLayout_Emoji__9Esaj {
  font-size: 1.8rem;
}
.ResultsConcertableLayout_DescriptionTitle__2zDhv p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.ResultsConcertableLayout_DescriptionContent__2KU8s {
  margin-top: 0.5em;
  color: #898786;
  font-size: 0.8rem;
}

.ResultsConcertableLayout_QuestionsHeader__1BCzm {
  color: #e0004d;
  margin-top: 0.7em;
}

.ResultsConcertableLayout_QuestionTitle__1ScXB {
  margin-top: 0.5em;
  font-size: 0.85em;
  display: flex;
}
.ResultsConcertableLayout_QuestionTitle__1ScXB p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ResultsConcertableLayout_QuestionEnsemble__1UMKJ {
  display: flex;
  margin: 1em 0;
}
.ResultsConcertableLayout_QuestionPercentage__3iTh7 {
  /* margin-right: 1em; */
  width: 6em;
}
.ResultsConcertableLayout_Winner__3tIbt {
  background-color: #e0004e71;
  padding: 0.3em;
  border-radius: 10px;
  color: #f3f2f0;
  /* font-weight: bold; */
}

/* ============================== radio form */
.ResultsConcertableLayout_RadioForm__2Wq61 {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.ResultsConcertableLayout_RadioElement__3PVMk {
  margin: 1em 0.8em;
}

.ResultsConcertableLayout_RadioElement__3PVMk label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.ResultsConcertableLayout_RadioElement__3PVMk p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.ResultsConcertableLayout_RadioElement__3PVMk img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 3.5em;
  border-radius: 50%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14);
}

.ResultsConcertableLayout_RadioElement__3PVMk input {
  display: none;
}

.ResultsConcertableLayout_RadioElement__3PVMk input[type="radio"]:checked + label img {
  border: 7px solid #e0004d;
}
.ResultsConcertableLayout_RadioElement__3PVMk input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* ============ END RADIO FORM */
.ResultsConcertableLayout_Button__2zw3D {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.ResultsConcertableLayout_CommentSection__1ulMI {
  margin-top: 1em;
}
.ResultsConcertableLayout_CommentGroup__34lHn {
  margin-top: 1em;
  background-color: #e0ecff83;
  padding: 1em;
  padding-top: 0.5em;
  border-radius: 10px;
}
.ResultsConcertableLayout_Comment__1kh5u {
  display: flex;
  margin-top: 0.8em;
}
.ResultsConcertableLayout_Comment__1kh5u p {
  margin-left: 1em;
}
.ResultsConcertableLayout_CommentAnswer__3Tins {
  margin-top: 0.5em;
  background: rgba(255, 255, 255, 0.397);
  padding: 1em;
  border-radius: 10px;
}

.ResultsConcertableLayout_MUIForm__3gjI4 {
  margin-left: 2em;
}

.ResultsConcertableLayout_NCSection__7_r_6 {
  margin-top: 1.1em;
}
.ResultsConcertableLayout_MapImage__XZAnz {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.ResultsConcertableLayout_DropdownIlots__LcEq_ {
  margin: 1em 0;
}

.ResultsConcertableLayout_Notices__19Onf {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .ResultsConcertableLayout_Card__1AXBy {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .ResultsConcertableLayout_LeftSection__6MOiw {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .ResultsConcertableLayout_RightSection__3NZ9G {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .ResultsConcertableLayout_RightFooter__2o2Pk {
    margin-top: 2em;
  }
}

.ResultsNonConcertableLayout_CardHeader__9plVh {
  display: flex;
  top: 0;
  left: 0;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.ResultsNonConcertableLayout_CardTitle__d_yXX h3 {
  margin-top: 1em;
  font-size: 1.2rem;
  color: #2a2f39;
  font-weight: bold;
}
.ResultsNonConcertableLayout_CardTitle__d_yXX h4 {
  font-size: 1rem;
  color: #2a2f39;
  font-weight: normal;
}

.ResultsNonConcertableLayout_Score__JKwb9 {
  margin-bottom: 0.2em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.ResultsNonConcertableLayout_Score__JKwb9 p {
  font-size: 0.9rem;
  color: #e0004d;
}
.ResultsNonConcertableLayout_Score__JKwb9 span {
  font-weight: bold;
}

.ResultsNonConcertableLayout_YoutubePlayer__3RNy5 {
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.ResultsNonConcertableLayout_CardContent__1k_0U {
  margin: 0 1.5em;
}

.ResultsNonConcertableLayout_Emoji__2FTMT {
  font-size: 1.8rem;
}
.ResultsNonConcertableLayout_DescriptionTitle__3LvGo p {
  color: #2d2926;
  font-weight: bold;
  font-size: 1.1rem;
}
.ResultsNonConcertableLayout_DescriptionContent__2dHmo {
  margin-top: 0.5em;
  color: #2d2926;
  font-size: 0.85rem;
}

.ResultsNonConcertableLayout_NCSection__36ZIc {
  margin-top: 1.1em;
}

.ResultsNonConcertableLayout_NCItem__3s_9O {
  margin-left: 1em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}
.ResultsNonConcertableLayout_NCItem__3s_9O .ResultsNonConcertableLayout_Emoji__2FTMT {
  font-size: 1.7em;
}
.ResultsNonConcertableLayout_NCItem__3s_9O p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.ResultsNonConcertableLayout_MapImage__22npO {
  margin-top: 0.85em;
}
.ResultsNonConcertableLayout_MapImage__22npO img {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

/* ============ END RADIO FORM */
.ResultsNonConcertableLayout_Button__UONyA {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: end;
}

.ResultsNonConcertableLayout_CommentSection__2aqr8 {
  margin-top: 1em;
}
.ResultsNonConcertableLayout_Comment__20_Su {
  display: flex;
  margin-top: 0.8em;
}
.ResultsNonConcertableLayout_Comment__20_Su p {
  margin-left: 1em;
}

/* Desktops */
@media screen and (min-width: 1400px) {
  .ResultsNonConcertableLayout_Card__4xCeH {
    width: 100%;
    display: flex;
    border-radius: 3px;
  }

  .ResultsNonConcertableLayout_LeftSection__2YR_H {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .ResultsNonConcertableLayout_RightSection__2JW7E {
    background-color: #f3f2f0;
    width: 50%;
    margin: 1em;
    margin-top: 4.5em;
    position: relative;
  }

  .ResultsNonConcertableLayout_MUIForm__1K8Lp {
    margin-bottom: 8em;
  }
  .ResultsNonConcertableLayout_RightFooter__2lfQk {
    position: absolute;
    bottom: 0;
    right: 1em;
    width: 90%;
  }
}

.PageOne_SectionTitle__2aBDA {
  text-align: center;
}
.PageOne_SectionTitle__2aBDA .PageOne_Emoji__3bTqo {
  font-size: 2.8em;
}
.PageOne_SectionTitle__2aBDA h3 {
  font-size: 1.5em;
}
.PageOne_SectionSubtitle__Kfqxl {
  font-weight: bold;
  font-size: 0.8em;
  margin: 0.8em 2em;
}

.PageOne_EmojiText__2Ln4Q {
  display: flex;
  margin: 0 2em;
}
.PageOne_EmojiText__2Ln4Q .PageOne_Emoji__3bTqo {
  font-size: 1.2em;
}

.PageOne_EmojiText__2Ln4Q p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.PageOne_ParticiperButton__3Spsw {
  text-align: center;
  margin: 1.5em 0;
}

.PageOne_Illustration__3ckjr {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
.PageOne_CarouselSection__24X_O {
  margin: 30px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.PageOne_CarouselSection__24X_O::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.PageOne_CarouselItem__2Ld_w {
  flex: 0 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.PageOne_CarouselItem__2Ld_w:first-child {
  margin-left: 40px;
}
.PageOne_CourselItem__1fKwX:last-child {
  margin-right: 20px;
}

.PageOne_GrandesEtapes__1Jkge {
  display: flex;
  justify-content: center;
}
.PageOne_GrandesEtapes__1Jkge img {
  width: 80%;
  max-width: 33em;
  margin-bottom: -3em;
}
.PageOne_HeroImage__nnNCa {
  width: 100%;
  margin-bottom: 1.5em;
  z-index: -1;
}
.PageOne_HeroImage__nnNCa img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .PageOne_HeroImage__nnNCa {
    width: 40em;
    margin: 0 auto;
    margin-bottom: 3em;
  }
  .PageOne_HeroImage__nnNCa img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  }
  .PageOne_Section__3m8lp {
    margin: 0 5.5em;
  }
  .PageOne_SectionTitle__2aBDA {
    display: flex;
    align-items: flex-end;
  }
  .PageOne_SectionSubtitle__Kfqxl {
    font-weight: bold;
    font-size: 0.9em;
    margin: 0.8em 0;
  }
  .PageOne_ContactForm__2AMsP {
    margin: 0 auto;
    max-width: 40em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .PageOne_HeroImage__nnNCa {
    width: 50em;
    margin: 0 auto;
    margin-bottom: 4em;
  }
  .PageOne_Section__3m8lp {
    margin: 0 20em;
  }
  .PageOne_Illustration__3ckjr {
    width: 25em;
    height: 25em;
  }
}

