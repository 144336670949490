/* SART ------------Basic Input--------------- */
.InputGroup {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.Input {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Input:focus {
  outline: none;
}
.Phone {
  padding-left: 35px;
}
.InputGroup i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
/* LABEL ======= */
.InputLabel {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Input:focus ~ .InputLabel,
.Input:valid ~ .InputLabel {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Bar {
  position: relative;
  display: block;
  width: 100%;
}
.Bar:before,
.Bar:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Bar:before {
  left: 50%;
}
.Bar:after {
  right: 50%;
}
/* active state */
.Input:focus ~ .Bar:before,
.Input:focus ~ .Bar:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Input:focus ~ .Highlight {
  animation: inputHighlighter 0.3s ease;
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
}
/* Animations */
@keyframes inputHighlighter {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes inputHighlighter {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Indicatif {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Input:focus ~ .Indicatif,
.Input:valid ~ .Indicatif {
  opacity: 1;
}

/* START -----------Radio Button----------- */

.RadioFormElement {
  /* margin-top: 10px; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.RadioFormElement::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.RadioDivElement {
  flex: 0 0 auto;
  margin: 20px 0;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  position: relative;
}
.RadioDivElement:first-child {
  margin-left: 10vw;
}

.RadioDivElement input {
  opacity: 0;
}

.RadioDivElement label {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1em;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  /* OmbreOUT */
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;
  cursor: pointer;
}
.RadioDivElement span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RadioDivElement input[type="radio"]:checked + label {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
  color: #e6404d;
  font-weight: bold;
}

/* END -----------Radio Button----------- */

/* Small tablets */
