.Filter {
  margin-bottom: 0.5em;
}
.Filter p {
  font-size: 0.9rem;
}
.Filter span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Categories form {
  display: flex;
  margin: 0.4rem 0;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.Categories form::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.Personnalisable {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Personnalisable label {
  background: #f1f3f5;
  color: #828489;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Personnalisable input[type="checkbox"] {
  display: none;
}

.Personnalisable input[type="checkbox"]:checked + label {
  background: #e0ecff;
  color: #263238;
}

/* =========================== */
.Informative {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Informative label {
  background: #f1f3f5;
  color: #828489;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Informative input[type="checkbox"] {
  display: none;
}

.Informative input[type="checkbox"]:checked + label {
  background: #fff3e0;
  color: #263238;
}
