.Blog {
  margin: 50px 0;
  box-sizing: border-box;
}

.BlogTitle {
  display: flex;
  margin: 0 20px;
}
.BlogTitle h2 {
  margin-left: 5px;
}

/* Small tablets */
@media (min-width: 620px) {
  .BlogTitle {
    margin: 0 50px;
  }
}
